import React, { useState } from "react";
import styled from "styled-components";
import { Link, animateScroll as scroll } from "react-scroll";
import { window } from 'browser-monads';

import MaxWidth from "components/functional/maxWidth";
import { media } from "utils/Media";
import WoodenBackgroundImg from "images/backgrounds/wooden-background.jpeg";
import SingleTimeline from "./singleTimeline";
import MobileTLIcon from "svgs/tl-button-mobile.svg";
import ToTopIcon from 'svgs/arrow-top.svg'

const MobileTimeLineStyles = styled.section`
  width: 100%;
`;
const HistoryHeaderStyles = styled.header`
  width: 100%;
  text-align: center;
  margin: 7.5rem auto 3rem auto;
  ul {
    margin-top: 2rem;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    li {
      p {
        cursor: pointer;
        font-size: 1.85rem;
        font-weight: 700;
        text-shadow: 1px 1px 3px #ffc700;
        &:hover,
        &:active {
          -webkit-text-fill-color: var(--main);
        }
        @supports (-webkit-text-stroke: 1px black) {
          -webkit-text-fill-color: #000000;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #ffc700;
        }
        @media ${media.md} {
          font-size: 2rem;
        }
      }
    }
    .active {
      p {
        -webkit-text-fill-color: var(--main);
        text-shadow: 1.5px 1.5px 7.5px #ffc700;
      }
    }
  }
  @media ${media.md} {
    margin: 2rem auto;
  }
`;
const HistoryTimelineStyles = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  .wrapper {
    svg {
      position: absolute;
      top: 1rem;
      left: -1.1rem;
      width: 40px;
      @media ${media.s} {
        top: 1.9rem;
      }
    }
  }
`;
const ScrollToTop = styled.aside`
@keyframes swing-in-right-fwd {
  0% {
    
            transform: rotateY(-100deg);
    
            transform-origin: right;
    opacity: 0;
  }
  100% {
    
            transform: rotateY(0);
    
            transform-origin: right;
    opacity: 1;
  }
}
	        animation: swing-in-right-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;

    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: var(--main);
    top: 50%;
    right: 0%;
    @media ${media.md} {
        top: 49%;
        right: 3.1rem;
    }
    svg {
        fill: var(--white);
        transform: rotate(180deg);
        width: 30px;
        height: 30px;
    }
`

const Line = styled.div`
  position: absolute;
  /* top: 1.45rem; */
  left: 0;
  width: 3px;
  height: 100%;
  background: var(--mainYellow);
  box-shadow: 1px 0px 10px 5px var(--main);
`;

const MobileTimeLine = ({ dates, decade }) => {
  const [active, setActive] = useState(0);
  const [scroll, setScroll] = useState(false)
  const changeScroll = () => {
    if (window.scrollY >= 150 || window.pageYOffset >= 150 ) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }
  window.addEventListener('scroll', changeScroll)
  const toggleActive = (id) => {
    if (active === id) {
      setActive(null);
    } else {
      setActive(id);
    }
  };

  return (
    <MaxWidth>
      <HistoryHeaderStyles>
        <h1 className="topTop">The History</h1>
        <ul>
          {decade.map((date, index) => {
            console.log(date.date)
            return (
              <Link
                to={date.date}
                spy={true}
                smooth={true}
                offset={-150}
                duration={700}
                key={index}
              >
                <li
                  key={index}
                  className={active === index ? "active" : ""}
                  tabIndex={index}
                  onClick={() => toggleActive(index)}
                  onKeyDown={() => toggleActive(index)}
                >
                  <p>{date.date}</p>
                </li>
              </Link>
            );
          })}
        </ul>
      </HistoryHeaderStyles>
      <HistoryTimelineStyles img={WoodenBackgroundImg}>
        <section>
          <div className="timeline">
            <Line />
            {dates.map((item, i) => {
              return (
                <section
                  className="position-relative py-3 wrapper"
                  key={i}
                  id={item.mobileAncor}
                >
                  <MobileTLIcon className="mobile-icon" />
                  <SingleTimeline data={item} $mobile />
                </section>
              );
            })}
          </div>
        </section>
      </HistoryTimelineStyles>
      {
          scroll ? <ScrollToTop><Link to="topTop" spy={true}
          smooth={true}
          offset={-70}
          duration={700}><ToTopIcon /></Link></ScrollToTop> : ''
      }
      
    </MaxWidth>
  );
};

export default MobileTimeLine;
