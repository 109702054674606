import React, { useState } from "react";
import { graphql } from "gatsby";


import Layout from "components/global/layout";
import { History, MobileHistory } from "components/history";

const meta = {
  title:
    "The Mousetrap Official Site - The world’s longest running play in the West End.",
  descirption:
    "The world’s longest running play is back onstage now in the West End. Performances now on sale until 2025! – book direct and book with confidence with no quibble exchanges.",
};

const HistoryPage = ({ data }) => {
  const { dates, decade } = data;
  const [swiper, setSwiper] = useState(null)
  return (
    <Layout title={meta.title} description={meta.descirption}>
      <section className="d-none d-lg-block">
        <History  dates={dates.nodes} decade={decade.nodes} />
      </section>
      <section className="d-lg-none">
        <MobileHistory dates={dates.nodes} decade={decade.nodes} />
      </section>
    </Layout>
  );
};

export default HistoryPage;

export const History2PageQuery = graphql`
  query History2PageQuery {
    dates: allHistory2Json {
      nodes {
        small
        medium
        large
        date
        paragraphs
        title
        ancor
        mobileAncor
        image {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }

    decade: allDecadeJson {
      nodes {
        date
        slide
      }
    }
  }
`;