import React, {useRef, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, FreeMode, Scrollbar} from "swiper";

import {media} from "utils/Media";

import MaxWidth from "components/functional/maxWidth";
import WoodenBackgroundImg from "images/backgrounds/wooden-background.jpeg";
import SingleTimeline from "./singleTimeline";
import SwipeIcon from "svgs/swipe.svg";
import ArrowSquiggleIcon from 'svgs/arrow-with-scribble.svg'

const HistoryHeaderStyles = styled.header`
  width: 100%;
  text-align: center;
  margin: 7.5rem auto 3rem auto;
  position: relative;

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    max-width: 750px;
    margin: 2rem auto 0 auto;

    li {
      p {
        scroll-behavior: smooth;
        cursor: pointer;
        font-size: 1.85rem;
        font-weight: 700;
        text-shadow: 1px 1px 3px #ffc700;

        &:hover,
        &:active {
          -webkit-text-fill-color: var(--main);
        }

        @supports (-webkit-text-stroke: 1px black) {
          -webkit-text-fill-color: #000000;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #ffc700;
        }
        @media ${media.md} {
          font-size: 2rem;
        }
      }
    }

    .active {
      p {
        -webkit-text-fill-color: var(--main);
        text-shadow: 1.5px 1.5px 7.5px #ffc700;
      }
    }
  }

  @media ${media.md} {
    margin: 2rem auto;
    .scroll-tooltip {
      position: absolute;
      z-index: 99;
      top: 4rem;
      left: -3rem;
      width: 200px;

      p {
        font-family: var(--fontHandwriting);
        font-size: 1.4rem;
      }

      .icon {
        transform: rotate(30deg);
        padding-top: .85rem;

        svg {
          fill: var(--white);
          width: 50px;
          height: 50px;
          animation: heartbeat 1.5s ease-in-out infinite both;
          @media ${media.xl} {
            width: 65px;
            height: 65px;
          }

        }

      }
    }
  }
`;

const HistoryTimelineStyles = styled.section`
  width: 100%;
  height: 100%;
  position: relative;

  section {
    margin: 1rem auto;
    height: 100%;
    overflow: hidden;
    position: relative;

    .timeline {
      /* width: 1000rem; */

      .swiper-slide {
        width: 100% !important;
      }

      .small {
        width: 200px !important;
      }

      .medium {
        width: 500px !important;
      }

      .large {
        width: 1000px !important;
      }
    }

    .wooden {
      /* display: none!important; */
      position: absolute;
      top: -5rem;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: var(--auto);
      z-index: -1;
      background-image: url(${(props) => props.img});
      background-size: cover;
      text-align: center;
      background-repeat: no-repeat;
      background-position: center;
      @media ${media.md} {
        background-size: contain;
        max-height: 50rem;
        margin: var(--auto);
      }
    }
  }

  .arrow {
    position: absolute;
    background-color: var(--main);
    width: 50px;
    height: 50px;
  }

  .arrow-left {
    left: -2rem;
  }

  .arrow-right {
    right: 2rem;
  }
`;

const Line = styled.div`
  position: absolute;
  top: 1.45rem;
  left: 1.5rem;
  width: 1280rem;
  height: 3px;
  background: var(--mainYellow);
  box-shadow: 1px 0px 10px 5px var(--main);
`;
const SwipeAnimation = styled.div`
  @keyframes slide-in-left {
    0% {
      transform: translateY(-180px);
      opacity: 0;
    }
    50% {
      transform: translateY(20);
      opacity: 1;
    }
    100% {
      transform: translateY(220px);
      opacity: 0;
    }
  }
  width: 80%;
  margin: var(--auto);
  text-align: center;

  svg {
    width: 70px;
    height: auto;
    fill: var(--main);
    transform: rotate(-90deg);

    .middle {
      animation: slide-in-left 2s infinite both;
    }
  }
`;

const History = ({dates, decade}) => {
    SwiperCore.use([Navigation]);
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
    const [active, setActive] = useState(0);
    const [swiper, setSwiper] = useState(null);
    const swiperRef = useRef();
    const toggleActive = (id, index) => {
        if (active === id) {
            setActive(null);
        } else {
            setActive(id);
        }
        if (swiper) swiper.slideTo(index);
    };
    const slideTo = (index) => {
    };
    return (
        <>
            <MaxWidth>
                <HistoryHeaderStyles>
                    <h1>The History</h1>
                    <ul>
                        {decade.map((date, index) => {
                            return (
                                <li
                                    key={index}
                                    className={active === index ? "active" : ""}
                                    tabIndex={index}
                                    onClick={() => toggleActive(index, date.slide)}
                                    onKeyDown={() => toggleActive(index, date.slide)}
                                >
                                    <p>{date.date}</p>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="scroll-tooltip">
                        <p>Scroll for more</p>
                        <div className="icon">
                            <ArrowSquiggleIcon/>
                        </div>
                    </div>
                </HistoryHeaderStyles>
            </MaxWidth>
            <MaxWidth>
                <HistoryTimelineStyles img={WoodenBackgroundImg}>
                    <section>
                        <div className="timeline">
                            <Line/>
                            <Swiper
                                modules={[FreeMode, Navigation]}
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                                grabCursor={true}
                                freeMode={true}
                                spaceBetween={50}
                                slidesPerView={"auto"}
                                onSlideChange={() => console.log("slide change")}
                                onSwiper={setSwiper}
                                ref={swiperRef}
                            >
                                    {dates.map((item, i) => {
                                        return (
                                            <SwiperSlide key={i} className={`${item.small && 'small'} ${item.medium && 'medium'} ${item.large && 'large'}`}>
                                                {item.small &&
                                                <SingleTimeline data={item} $small/>
                                                }
                                                {item.medium &&
                                                <SingleTimeline data={item} $medium/>
                                                }
                                                {item.large &&
                                                <SingleTimeline data={item} $large/>
                                                }
                                            </SwiperSlide>
                                        )
                                    })}
                            </Swiper>
                        </div>
                        <div className="wooden"/>
                    </section>
                </HistoryTimelineStyles>
            </MaxWidth>
        </>
    );
};

export default History;
