import GetImage from "components/functional/getImage";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import TLButton from "svgs/tl-button.svg";

const SingleTimelineStyles = styled.div`
    scroll-snap-align: start;
    position: relative;
    svg {
      margin-left:3.25rem ;
    }
  .text {
    h4 {
      @supports (-webkit-text-stroke: 1px black) {
        color: var(--main);
        -webkit-text-fill-color: var(--main);
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #ffc700;
      }
      font-size: 4rem;
      font-weight: 700;
      text-shadow: 1px 1px 10px #ffc700;
    }
    p {
      strong {
        color: var(--main);
        font-size: 1.5rem;
      }
    }
    .paragraphs {
      p {
        font-weight: 200 !important;
        margin-bottom: 1rem;
        > strong  {
          font-size: 1rem!important;
        }
      }
    }
  }
  .image {
    margin-top: 3.5rem;
    .gatsby-image-wrapper {
      border-radius: 25px;
      padding: 12px;
      border: solid #f7a802 4px;
      background: #f7a802;
      background: linear-gradient(180deg, #f7a802 0%, #f8c706 100%);
      box-shadow: 0 0 10px 10px #8c290d;
      /* width: auto;
      height: 100%; */
      min-width: 450px;
    }
  }
  .withImg {
    display : flex;
    gap :  1.5rem;
  }
  
  ${(props) =>
    props.$small &&
    css`
      /* width: 220px !important; */
    `}
  ${(props) =>
    props.$medium &&
    css`
      .withImg {
        width: 900px !important;
          grid-template-columns: 400px 1fr;
        
      }
      .noImage {
        width: 500px;
      }
    `}
  ${(props) =>
    props.$large &&
    css`
      /* width: 1100px; */
      .withImg {
          grid-template-columns: 600px 1fr;
        
      }
      .noImage {
        /* width: 1200px!important; */
        min-width: 600px;
      }
    `}
  ${props => props.$mobile && css`
  width: 90%;
  margin-left: auto;
  svg {
    /* margin: 0;
    padding:0 ;
    width: 20px;
    height: 20px;
    fill: red;
    position: relative; */
    display: none;

  }
  .text .paragraphs p {
    margin-bottom: 0;
  }
  .withImg {
        width: 100%;
        display: block;
        .image {
          width: 100%;
          margin-top: 1rem;
          .gatsby-image-wrapper {
            height: auto;
            width: 100%;
            min-width: 10px;
            max-width: 400px;
          }
        }
      }
  `}

`;


const SingleTimeline = props => {
  const { data } = props;
  let startId = "";
  let endId = "";
  if (data.ancor) {
    startId = data.ancor;
  }
  if (data.ancorTwo) {
    endId = data.ancorTwo;
  }
  return (
    <SingleTimelineStyles {...props} >
      {data.image ? (
        <div className="withImg">
          <div className={data.small ? "small text" : "text"}>
            <TLButton />
            <h4>{data.date}</h4>
            {data.title ? (
              <p>
                <strong>{data.title}</strong>
              </p>
            ) : (
              ""
            )}
            {data.paragraphs.map((paragraph, i) => {
              return (
                <div
                key={i}
                  className="paragraphs"
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              );
            })}
          </div>

          <div className="image">
            <GetImage data={data.image} />
          </div>
        </div>
      ) : (
        <div className={data.small ? "small text noImage" : "text noImage"}>
          <TLButton />
          <h4>{data.date}</h4>
          {data.title ? (
            <p>
              <strong>{data.title}</strong>
            </p>
          ) : (
            ""
          )}
          {data.paragraphs.map((paragraph, i) => {
            return (
              <div
              key={i+10}
                className="paragraphs"
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            );
          })}
        </div>
      )}
    </SingleTimelineStyles>
  );
};

export default SingleTimeline;
